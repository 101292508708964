.App {
  text-align: center;
}

.content-container {
  justify-content: center;
  margin: auto;
  height: auto;
  max-width: 80rem;
  padding: 0 0.5rem;
}

.myButton {
  padding: 0.5em 1em;
  background-color: transparent;
  border: solid;
  border-color: rgb(42, 94, 172);
  border-radius: 4px;
  color: rgb(42, 94, 172);
  cursor: pointer;
}
.myButton:hover {
  background-color: rgb(42, 94, 172);
  color: white;
}