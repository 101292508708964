/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

#homepage {
  justify-content: center;
  text-align: center;
  background-image: url("../images/food-background.jpeg");
  background-attachment: fixed;
  background-size: cover;
}

/* iphones don't support this feature of course */
@supports (-webkit-touch-callout: none) {
  #homepage {
    background-size: 1000px;
  }
}

#homepage #div1 {
  background-color: rgb(255, 255, 255);
  border-width: thin;
  padding: 3rem 0;
}

#homepage #div1 #approach {
  font-style: italic;
  font-weight: bolder;
  font-size: 2rem;
}

#homepage #div1 #habits {
  font-size: 1.2rem;
}

#homepage #div2 {
  background-color: rgb(97, 55, 146);
  color: white;
  padding: 2rem 0;
}

#homepage #div2 .wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
@media screen and (min-width: 576px) {
  #homepage #div2 .wrapper {
    padding: 0 5rem;
    font-size: 1.2rem;
  } 
}

#homepage #div2 #coaching {
  color: rgb(162, 211, 195);
  font-size: 2rem;
  font-style: italic;
  margin-top: 0.7rem;
}

#homepage #div3 {
  background-color: white;
  padding: 2rem 0;
}

#homepage #div4 {
  height: 500px;
  background-color: transparent;
}

#homepage #div5 {
  background-color: white;
}

#homepage #div5 .wrapper {
  display: grid;
  padding: 2rem 0;
  row-gap: 2rem;
}
@media screen and (min-width: 1140px) {
  #homepage #div5 .wrapper {
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem 2rem;
  }
}
#homepage #div5 .wrapper div {
  font-size: 1.5rem;
  font-weight: 500;
}

#homepage #div6 {
  background-color: rgb(129, 150, 93);
  color: white;
  padding: 3rem 0;
  /* height: 300px; */
  border-top: solid;
  border-bottom: solid;
  border-color: black;
}

#homepage #div6 p {
  font-style: italic;
  font-size: 2em;
  padding-bottom: 2rem;
}

#homepage #div6 h2 {
  font-weight: 300;
  font-style: italic;
}

#homepage #div7 {
  background-color: white;
  padding-top: 2rem;
}

#homepage #div7 .wrapper {
  display: grid;
  place-items: center;
}
@media screen and (min-width: 576px) and (max-width: 998.98px) {
  #homepage #div7 .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1000px) {
  #homepage #div7 .wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
