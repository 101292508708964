/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

#contactpage {
  justify-content: center;
  text-align: center;
  background: radial-gradient(circle at 100%,
  rgb(8, 117, 63),
  rgb(45, 167, 184) 50%,
  rgb(26, 71, 167) 74.8%,
  rgb(185, 185, 185) 75%,
  rgb(90, 90, 90) 90%);
  overflow: hidden;
}

#contactpage #div1 {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5rem 0;
}
@media screen and (min-width: 576px) {
  #contactpage #div1 {
    margin: 10rem 0;
    border-top: solid;
    border-bottom: solid;
    border-width: thin;
  }
}

#contactpage .content-container {
  max-width: 40rem;
}

#contactpage #form {
  margin: 3rem 0;
}

#contactpage i {
  text-transform: uppercase;
}

#contactpage #email-addr {
  font-size: 18px;
  color: maroon;
}

#contactpage #quote {
  font-size: larger;
}