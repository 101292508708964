.box {
  margin: 10px 0;
  padding: 12px 20px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  transition: 0.5s;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.box:focus {
  background-color: azure;
  outline: none;
  border-color: black;
}

::placeholder {
  font-style: italic;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 14px;
}

#message {
  min-height: 12em;
}
