#footer {
  padding: 3rem 0;
  font-size: 12px;
  text-align: start;
}

#footer .content-container {
  padding: 0 1rem;
}

#social-links {
  text-align: end;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

#social-links a:first-of-type {
  background-color: transparent;
  border: none;
  padding-right: 0.5rem;
}

#social-links a img {
  height: 30px;
  width: auto;
}

#copyright {
  color: gray;
}

#disclaimer {
  font-size: larger;
  font-style: italic;
}

.otherInfo {
  font-size: smaller;
  text-align: center;
}
