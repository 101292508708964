/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

#aboutpage {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  justify-content: center;
  text-align: center;
  background: radial-gradient(circle at 100%,
  rgb(8, 117, 63),
  rgb(45, 167, 184) 50%,
  rgb(26, 71, 167) 74.8%,
  rgb(185, 185, 185) 75%,
  rgb(90, 90, 90) 90%);
  overflow: hidden;
}

#aboutpage #div1 {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5rem 0;
}
@media screen and (min-width: 576px) {
  #aboutpage #div1 {
    margin: 10rem 0;
    border-top: solid;
    border-bottom: solid;
    border-width: thin;
  }
}

#aboutpage .wrapper {
  display: grid;
  gap: 2rem;
}
@media screen and (min-width: 992px) {
  #aboutpage .wrapper {
    grid-template-columns: 2fr 1fr;
  }
}

#aboutpage #process h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: center;
}
#aboutpage #process h2 {
  font-size: 2.5rem;
  text-align: center;
}

#aboutpage #process #what-change {
  margin-top: -0.5rem;
  font-size: x-large;
  font-weight: 600;
  color: rgb(99, 153, 79);
}

#aboutpage #schedule h1 {
  text-transform: uppercase;
  color: rgb(39, 19, 58);
}

#aboutpage #schedule #name {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgb(39, 19, 58);
  font-size: xx-large;
}
#aboutpage #schedule #nbc-hwc {
  margin-top: 0;
  color: rgb(61, 57, 65);
  font-size: xx-large;
  font-style: italic;
}
