body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.content-container {
  justify-content: center;
  margin: auto;
  height: auto;
  max-width: 80rem;
  padding: 0 0.5rem;
}

.myButton {
  padding: 0.5em 1em;
  background-color: transparent;
  border: solid;
  border-color: rgb(42, 94, 172);
  border-radius: 4px;
  color: rgb(42, 94, 172);
  cursor: pointer;
}
.myButton:hover {
  background-color: rgb(42, 94, 172);
  color: white;
}
/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

.navbar {
  padding: 0;
}

.navbar img {
  width: 120px;
  background-color: transparent;
}
@media screen and (min-width: 992px) {
  .navbar img {
    width: 300px;
  }
}

@media screen and (min-width: 576px) {
  #responsive-navbar-nav {
    justify-content: right;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1%;
  }
}

.navbar .brand {
  padding: 0;
}

.navbar .container {
  position: relative;
}


#footer {
  padding: 3rem 0;
  font-size: 12px;
  text-align: start;
}

#footer .content-container {
  padding: 0 1rem;
}

#social-links {
  text-align: end;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

#social-links a:first-of-type {
  background-color: transparent;
  border: none;
  padding-right: 0.5rem;
}

#social-links a img {
  height: 30px;
  width: auto;
}

#copyright {
  color: gray;
}

#disclaimer {
  font-size: larger;
  font-style: italic;
}

.otherInfo {
  font-size: smaller;
  text-align: center;
}

/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

#homepage {
  justify-content: center;
  text-align: center;
  background-image: url(/static/media/food-background.8fc628a2.jpeg);
  background-attachment: fixed;
  background-size: cover;
}

/* iphones don't support this feature of course */
@supports (-webkit-touch-callout: none) {
  #homepage {
    background-size: 1000px;
  }
}

#homepage #div1 {
  background-color: rgb(255, 255, 255);
  border-width: thin;
  padding: 3rem 0;
}

#homepage #div1 #approach {
  font-style: italic;
  font-weight: bolder;
  font-size: 2rem;
}

#homepage #div1 #habits {
  font-size: 1.2rem;
}

#homepage #div2 {
  background-color: rgb(97, 55, 146);
  color: white;
  padding: 2rem 0;
}

#homepage #div2 .wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
@media screen and (min-width: 576px) {
  #homepage #div2 .wrapper {
    padding: 0 5rem;
    font-size: 1.2rem;
  } 
}

#homepage #div2 #coaching {
  color: rgb(162, 211, 195);
  font-size: 2rem;
  font-style: italic;
  margin-top: 0.7rem;
}

#homepage #div3 {
  background-color: white;
  padding: 2rem 0;
}

#homepage #div4 {
  height: 500px;
  background-color: transparent;
}

#homepage #div5 {
  background-color: white;
}

#homepage #div5 .wrapper {
  display: grid;
  padding: 2rem 0;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}
@media screen and (min-width: 1140px) {
  #homepage #div5 .wrapper {
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem 2rem;
  }
}
#homepage #div5 .wrapper div {
  font-size: 1.5rem;
  font-weight: 500;
}

#homepage #div6 {
  background-color: rgb(129, 150, 93);
  color: white;
  padding: 3rem 0;
  /* height: 300px; */
  border-top: solid;
  border-bottom: solid;
  border-color: black;
}

#homepage #div6 p {
  font-style: italic;
  font-size: 2em;
  padding-bottom: 2rem;
}

#homepage #div6 h2 {
  font-weight: 300;
  font-style: italic;
}

#homepage #div7 {
  background-color: white;
  padding-top: 2rem;
}

#homepage #div7 .wrapper {
  display: grid;
  place-items: center;
}
@media screen and (min-width: 576px) and (max-width: 998.98px) {
  #homepage #div7 .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1000px) {
  #homepage #div7 .wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

#aboutpage {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  justify-content: center;
  text-align: center;
  background: radial-gradient(circle at 100%,
  rgb(8, 117, 63),
  rgb(45, 167, 184) 50%,
  rgb(26, 71, 167) 74.8%,
  rgb(185, 185, 185) 75%,
  rgb(90, 90, 90) 90%);
  overflow: hidden;
}

#aboutpage #div1 {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5rem 0;
}
@media screen and (min-width: 576px) {
  #aboutpage #div1 {
    margin: 10rem 0;
    border-top: solid;
    border-bottom: solid;
    border-width: thin;
  }
}

#aboutpage .wrapper {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
}
@media screen and (min-width: 992px) {
  #aboutpage .wrapper {
    grid-template-columns: 2fr 1fr;
  }
}

#aboutpage #process h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: center;
}
#aboutpage #process h2 {
  font-size: 2.5rem;
  text-align: center;
}

#aboutpage #process #what-change {
  margin-top: -0.5rem;
  font-size: x-large;
  font-weight: 600;
  color: rgb(99, 153, 79);
}

#aboutpage #schedule h1 {
  text-transform: uppercase;
  color: rgb(39, 19, 58);
}

#aboutpage #schedule #name {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgb(39, 19, 58);
  font-size: xx-large;
}
#aboutpage #schedule #nbc-hwc {
  margin-top: 0;
  color: rgb(61, 57, 65);
  font-size: xx-large;
  font-style: italic;
}

/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

#contactpage {
  justify-content: center;
  text-align: center;
  background: radial-gradient(circle at 100%,
  rgb(8, 117, 63),
  rgb(45, 167, 184) 50%,
  rgb(26, 71, 167) 74.8%,
  rgb(185, 185, 185) 75%,
  rgb(90, 90, 90) 90%);
  overflow: hidden;
}

#contactpage #div1 {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5rem 0;
}
@media screen and (min-width: 576px) {
  #contactpage #div1 {
    margin: 10rem 0;
    border-top: solid;
    border-bottom: solid;
    border-width: thin;
  }
}

#contactpage .content-container {
  max-width: 40rem;
}

#contactpage #form {
  margin: 3rem 0;
}

#contactpage i {
  text-transform: uppercase;
}

#contactpage #email-addr {
  font-size: 18px;
  color: maroon;
}

#contactpage #quote {
  font-size: larger;
}
.box {
  margin: 10px 0;
  padding: 12px 20px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  transition: 0.5s;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.box:focus {
  background-color: azure;
  outline: none;
  border-color: black;
}

::-webkit-input-placeholder {
  font-style: italic;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 14px;
}

::placeholder {
  font-style: italic;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 14px;
}

#message {
  min-height: 12em;
}

/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

#infopage {
  justify-content: center;
  text-align: center;
  background: radial-gradient(circle at 100%,
  rgb(8, 117, 63),
  rgb(45, 167, 184) 50%,
  rgb(26, 71, 167) 74.8%,
  rgb(185, 185, 185) 75%,
  rgb(90, 90, 90) 90%);
  overflow: hidden;
}

#infopage #div1 {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5rem 0;
}
@media screen and (min-width: 576px) {
  #infopage #div1 {
    margin: 10rem 0;
    border-top: solid;
    border-bottom: solid;
    border-width: thin;
  }
}

#infopage #quote {
  margin-top: 3rem;
  padding-bottom: 4rem;
}

#infopage .wrapper {
  display: grid;
  margin-top: 5rem;
}
@media screen and (max-width: 767.98px) {
  #infopage .wrapper div {
    border-top: solid;
    border-bottom: solid;
    border-width: thin;
    margin: 0 1rem;
    padding: 2rem 0;
  }
  #infopage .wrapper div:first-of-type {
    border-top: none;
  }
  #infopage .wrapper div:last-of-type {
    border-bottom: none;
  }
}
@media screen and (min-width: 768px) {
  #infopage .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  #infopage .wrapper div {
    border-left: solid;
    border-right: solid;
    border-width: thin;
    padding: 2rem 2rem;
  }
  #infopage .wrapper div:first-of-type {
    border-left: none;
  }
  #infopage .wrapper div:last-of-type {
    border-right: none;
  }
}

.highlight {
  font-weight: bold;
}

