/* global CSS variables. Breakpoints from bootstrap are used and can be found here: https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
:root {
  --xs-min-width: 0;
  --sm-min-width: 576px;
  --md-min-width: 768px;
  --lg-min-width: 992px;
  --xl-min-width: 1200px;
  --xxl-min-width: 1400px;

  --xs-max-width: 0;
  --sm-max-width: 575.98px;
  --md-max-width: 767.98px;
  --lg-max-width: 991.98px;
  --xl-max-width: 1199.98px;
  --xxl-max-width: 1399.98px;
}

.navbar {
  padding: 0;
}

.navbar img {
  width: 120px;
  background-color: transparent;
}
@media screen and (min-width: 992px) {
  .navbar img {
    width: 300px;
  }
}

@media screen and (min-width: 576px) {
  #responsive-navbar-nav {
    justify-content: right;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1%;
  }
}

.navbar .brand {
  padding: 0;
}

.navbar .container {
  position: relative;
}

